import React from 'react';
const Offices = () => {
    return ( 
        <div className='percent-container'>
            
            <div className='office-container'>
                <div className='office-div'>
                    
                    <div>
                        
                    </div>
                </div>
                {/* <div className='office-div'>
                    <div>
                        <img src={canada} alt='USA' className='country-flag' />
                    </div>
                    <div>
                        <h3 className='office-title'>Canada</h3>
                        <p>76 Elgin St, Suite 210</p>
                        <p>Ottawa,ON K1B 2H9</p>
                        <p>Email: cainfo@voyagetechi.com</p>
                    </div>
                </div> */}
            </div>
        </div>
     );
}
 
export default Offices;